import React from 'react';
import PropTypes from 'prop-types';
import Layout from './Layout';
import Classes from './Menubar.module.css';
import Sidebar from './Sidebar';
import { Link } from 'gatsby';

const Menubar = props => {
  return (
    <div className={Classes.Header}>
      <section>
        <header>
          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/">
                  <span className={Classes.blink}>NEW</span>
                </Link>
              </li>
              <li>
                <Link to="/blogs">Videos</Link>
              </li>
              <li>
                <Link to="/downloads">Downloads</Link>
              </li>
              <li>
                <Link to="/publications">Publications </Link>
              </li>
            </ul>
          </nav>
        </header>
      </section>
    </div>
  );
};

Menubar.propTypes = {};

export default Menubar;

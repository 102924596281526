import React, { Component } from 'react';
import Scrollspy from 'react-scrollspy';
import Scroll from './Scroll';

import avatar from '../assets/images/avatar.png';
import { Link } from 'gatsby';
import Header from './Header/header';
import { slide as Menu } from 'react-burger-menu';

export class Sidebar extends Component {
  constructor(props) {
    super(props);

    switch (this.props.showTabs) {
      case 'false':
        this.state = {
          tabs: [{ content: '', href: '#about' }],
          showTabs: true,
        };
        break;
      case 'love':
        this.state = {
          tabs: [
            { content: 'Top', href: 'top' },
            { content: 'Quran', href: 'quran' },
            { content: 'Baqarah', href: 'baqarah' },
            { content: 'prophet', href: 'prophet' },
          ],
          showTabs: true,
        };
        break;
      case 'ramadan':
        this.state = {
          tabs: [
            { content: 'Top', href: 'top' },
            { content: 'After', href: 'after' },
          ],
          showTabs: true,
        };
        break;
      case 'steps':
        this.state = {
          tabs: [
            { content: 'Top', href: 'top' },
            { content: 'References', href: 'references' },
          ],

          showTabs: true,
        };
        break;
      case 'main':
        this.state = {
          tabs: [
            { content: 'Top', href: 'top' },
            { content: 'Ramadan', href: 'ramadan' },
          ],
          showTabs: true,
        };
        break;
      case 'madarij':
        this.state = {
          tabs: [
            { content: 'Top', href: 'happy' },
            { content: 'introduction', href: 'introduction' },
            { content: 'Help', href: 'help' },
            { content: 'Love', href: 'love' },
            { content: 'Protect Your Heart', href: 'heart' },
            { content: 'Sincerity', href: 'Sincerity' },
            { content: 'Perfection', href: 'step4' },
            { content: 'CONSCIOUSNESS', href: 'stage1' },
            { content: 'Trust', href: 'trust' },
            { content: 'patience', href: 'patience' },
            { content: 'gratitude', href: 'gratitude' },
            { content: 'References', href: 'references' },
          ],
          showTabs: true,
        };
        break;
      case 'happy':
        this.state = {
          tabs: [
            { content: 'Steps to Happiness', href: 'happy' },
            { content: '1. Introduction', href: 'introduction' },
            { content: '2. Duaa', href: 'step1' },
            { content: '3. Opportunity', href: 'step2' },
            { content: 'References', href: 'references' },
          ],
          showTabs: true,
        };
        break;

      case 'resume':
        this.state = {
          tabs: [
            { content: 'Top', href: 'about' },
            { content: 'Duaa الدعاء', href: 'duaa' },
            { content: 'Madarij Assalekeen مدارج السالكين', href: 'Madarij' },
          ],
          showTabs: true,
        };
        break;

      default:
        this.state = {
          tabs: [{ content: 'I Love Allah', href: 'about' }],
          showTabs: true,
        };
    }
  }

  render() {
    let { tabs } = this.state;

    return (
      <nav
        className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top"
        id="sideNav"
      >
        <span>
          <Link to="/" style={{ color: 'white' }}>
            Home
          </Link>
        </span>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <Scrollspy
            items={tabs.map(s => s.href)}
            currentClassName="active"
            offset={-300}
            className="navbar-nav"
          >
            {tabs.map((tab, i) => {
              const { href, content } = tab;
              return (
                <li className="nav-item" key={href}>
                  <Scroll type="id" element={href}>
                    <a className="nav-link" href={`#${href}`}>
                      {content}
                    </a>
                  </Scroll>
                </li>
              );
            })}
          </Scrollspy>
        </div>
        <span className="d-none d-lg-block">
          <img
            className="img-fluid img-profile rounded-circle mx-auto mb-2"
            src={avatar}
            alt=""
            height="70"
            width="70"
          />
        </span>

        <dev
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="true"
          aria-label="Toggle navigation"
        >
          <Menu right className="Header__menu">
            <Link to="/" className="Header__menu__item menuTitle">
              Home
            </Link>

            <Link to="/" className="Header__menu__item menuTitle">
              Menu
            </Link>
            <hr />
            {tabs.map((tab, i) => {
              const { href, content } = tab;
              return (
                <li className="nav-item" key={href}>
                  <Link className="nav-link Header__menu__item" to={`#${href}`}>
                    {content}
                  </Link>
                </li>
              );
            })}
          </Menu>
        </dev>
      </nav>
    );
  }
}

export default Sidebar;
